import React, { useCallback, useEffect, useState } from "react";

import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";
import {
  getCategoryClientHomeApi,
  getClientHomeApi,
  getPlaces,
} from "../../api/home.api";
import NoFound from "../../components/NoFound";
import Banner from "../../components/banner";
import BaseButton from "../../components/button/BaseButton";
import CardBlog from "../../components/cardBlog/CardBlog";
import CardBlogSkeletonLoading, {
  dataCardBlogSkeletonLoading,
} from "../../components/cardBlog/CardBlogSkeletonLoading";
import BaseSelectV2 from "../../components/select/BaseSelectV2";
import { SearchIcon } from "../../constant/icon";
import { useLanguage } from "../../hooks/useLanguage";
import { buildTimeRangeFilter } from "../../utils/date";
import { delayMs } from "../../utils/delay";
import { handleFormatText } from "../../utils/utils";

const LIMIT = 3;
const defaultPagination = {
  totalItem: 0,
  totalPage: 1,
  currentPage: 1,
};
export default function Home() {
  const { t } = useTranslation();
  const { nameWithLanguage, currentLanguage } = useLanguage();

  const [allCategories, setAllCategories] = useState();
  const [dataEventSports, setDataEventSports] = useState();
  const [dataEventConcerts, setDataEventConcerts] = useState();
  const [dataEventFoodCourt, setDataEventFoodCourt] = useState();
  const [isLoadingSport, setIsLoadingSport] = useState(true);
  const [isLoadingConcerts, setIsLoadingConcerts] = useState(true);
  const [isLoadingFoodCourt, setIsLoadingFoodCourt] = useState(true);
  const [dataPlaces, setDataPlaces] = useState();
  const [dataSearch, setDataSearch] = useState({
    keyWordSearch: "",
    placeSearch: { ...dataPlaces },
    timeValueRangeSearch: {
      startDate: null,
      endDate: null,
    },

    paginationSport: { ...defaultPagination },
    paginationConcert: { ...defaultPagination },
    paginationFoodCourt: { ...defaultPagination },
    pagination: { ...defaultPagination },
  });

  const [dataFilter, setDataFilter] = useState({
    category: {},
    subCategory: {},
  });

  useEffect(() => {
    getSubCategory();
    getDataPlaces();
  }, []);

  useEffect(() => {
    const { category, subCategory } = dataFilter;
    if (isEmpty(category) && isEmpty(subCategory)) return;
    getDataEventsApi({
      ...(category?.value !== "all_category"
        ? { category: category?.category }
        : {}),
      ...(category?.value !== "all_category"
        ? { subCategoryId: subCategory?.id }
        : { subCategoryId: subCategory?.id }),
    });
  }, [dataFilter]);

  const getSubCategory = useCallback(async () => {
    try {
      const responseCategories = await getCategoryClientHomeApi();
      const allEvent = [];
      responseCategories?.data?.map((event) => {
        if (event?.subCategories) allEvent.push(...event.subCategories);
        event?.subCategories.unshift({
          category: t("concert"),
          nameAr: t("all_event"),
          nameEn: t("all_event"),
          keyTranslate: "any_category",
        });
      });
      setAllCategories([
        {
          value: "all_category",
          category: t("any_category"),
          subCategories: allEvent,
          keyTranslate: "any_category",
        },
        ...responseCategories?.data,
      ]);
      allEvent.unshift({
        value: "all_category",
        nameAr: t("all_event"),
        nameEn: t("all_event"),
        keyTranslate: "any_category",
      });
      setDataFilter({
        subCategory: allEvent[0],
        category: {
          value: "all_category",
          category: t("any_category"),
          keyTranslate: "any_category",
          subCategories: allEvent,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  }, [t]);

  const onChangeObjectSearch = (e, type) => {
    if (type) {
      return setDataSearch({ ...dataSearch, [type]: e });
    }
    const { name, value } = e.target;
    setDataSearch({ ...dataSearch, [name]: value });
  };

  const onResetPagination = () => {
    setDataSearch({
      ...dataSearch,
      paginationConcert: { ...defaultPagination },
      paginationFoodCourt: { ...defaultPagination },
      paginationSport: { ...defaultPagination },
      pagination: { ...defaultPagination },
    });
  };

  const onChangeObjectFilter = (e, type) => {
    if (type) {
      console.log("type", type);
      console.log("22", e.subCategories);
      onResetPagination();
      return setDataFilter({
        ...dataFilter,
        subCategory:
          type === "category" ? e.subCategories[0] : dataFilter.subCategory,
        [type]: e,
      });
    }
  };
  const setIsLoadingWithType = (category, status) => {
    if (category === "CONCERT") {
      setIsLoadingConcerts(status);
    } else if (category === "SPORT") {
      setIsLoadingSport(status);
    } else if (category === "FOOD_COURT") {
      setIsLoadingFoodCourt(status);
    } else {
      setIsLoadingConcerts(status);
      setIsLoadingSport(status);
      setIsLoadingFoodCourt(status);
    }
  };
  const setDataWithType = (category, status, data) => {
    const { CONCERT = {}, SPORT = {}, FOOD_COURT = {} } = data;
    const { events: dataConcert } = CONCERT;
    const { events: dataSport } = SPORT;
    const { events: dataFoodCourt } = FOOD_COURT;

    if (category === "SPORT") {
      setDataEventSports(
        status ? [...dataEventSports, ...dataSport] : dataSport
      );
      if (!status) {
        setDataEventConcerts([]);
        setDataEventFoodCourt([]);
      }
    } else if (category === "CONCERT") {
      setDataEventConcerts(
        status ? [...dataEventConcerts, ...dataConcert] : dataConcert
      );
      if (!status) {
        setDataEventSports([]);
        setDataEventFoodCourt([]);
      }
    } else if (category === "FOOD_COURT") {
      setDataEventFoodCourt(
        status ? [...dataEventFoodCourt, ...dataFoodCourt] : dataFoodCourt
      );
      if (!status) {
        setDataEventSports([]);
        setDataEventConcerts([]);
      }
    } else {
      setDataEventSports(
        status ? [...dataEventSports, ...dataSport] : dataSport
      );
      setDataEventConcerts(
        status ? [...dataEventConcerts, ...dataConcert] : dataConcert
      );
      setDataEventFoodCourt(
        status ? [...dataEventFoodCourt, ...dataFoodCourt] : dataFoodCourt
      );
    }
  };
  const setPaginationWithType = (category, isFetchNext, data) => {
    const { CONCERT = {}, SPORT = {}, FOOD_COURT = {} } = data;
    const { total: totalItemConcert, totalPage: totalPageConcert } = CONCERT;
    const { total: totalItemSport, totalPage: totalPageSport } = SPORT;
    const { total: totalItemFoodCourt, totalPage: totalPageFoodCourt } =
      FOOD_COURT;

    const onSetPaginationSport = () => {
      setDataSearch({
        ...dataSearch,
        paginationSport: {
          totalItem: totalItemSport,
          totalPage: totalPageSport,
          currentPage: isFetchNext
            ? dataSearch.paginationSport.currentPage + 1
            : 1,
        },
      });
    };
    const onSetPaginationConcert = () => {
      setDataSearch({
        ...dataSearch,
        paginationConcert: {
          totalItem: totalItemConcert,
          totalPage: totalPageConcert,
          currentPage: isFetchNext
            ? dataSearch.paginationConcert.currentPage + 1
            : 1,
        },
      });
    };
    const onSetPaginationFoodCourt = () => {
      setDataSearch({
        ...dataSearch,
        paginationFoodCourt: {
          totalItem: totalItemFoodCourt,
          totalPage: totalPageFoodCourt,
          currentPage: isFetchNext
            ? dataSearch.paginationFoodCourt.currentPage + 1
            : 1,
        },
      });
    };

    if (category === "SPORT") {
      onSetPaginationSport();
    } else if (category === "CONCERT") {
      onSetPaginationConcert();
    } else if (category === "FOOD_COURT") {
      onSetPaginationFoodCourt();
    } else {
      setDataSearch({
        ...dataSearch,
        paginationSport: {
          totalItem: totalItemSport,
          totalPage: totalPageSport,
          currentPage: isFetchNext
            ? dataSearch.paginationSport.currentPage + 1
            : 1,
        },
        paginationConcert: {
          totalItem: totalItemConcert,
          totalPage: totalPageConcert,
          currentPage: isFetchNext
            ? dataSearch.paginationConcert.currentPage + 1
            : 1,
        },
        paginationFoodCourt: {
          totalItem: totalItemFoodCourt,
          totalPage: totalPageFoodCourt,
          currentPage: isFetchNext
            ? dataSearch.paginationFoodCourt.currentPage + 1
            : 1,
        },
      });
    }
  };
  const getDataEventsApi = async ({
    limit = LIMIT,
    page = 1,
    category,
    subCategoryId,
    isFetchNext,
    place,
  }) => {
    try {
      setIsLoadingWithType(category, true);

      const response = await getClientHomeApi({
        params: {
          limit,
          page,
          language: currentLanguage.toUpperCase(),
          ...(dataSearch.keyWordSearch
            ? { keyWord: dataSearch.keyWordSearch }
            : {}),
          category,
          subCategoryId,
          time: buildTimeRangeFilter(dataSearch.timeValueRangeSearch),
          ...(dataSearch.placeSearch
            ? { place: dataSearch.placeSearch?.value }
            : {}),
        },
      });

      setPaginationWithType(category, isFetchNext, response.data);

      setDataWithType(category, isFetchNext, response.data);

      delayMs(500).then(() => {
        setIsLoadingWithType(category, false);
      });
    } catch (error) {
      console.log("error", error);

      delayMs(500).then(() => {
        setIsLoadingWithType(category, false);
      });
    }
  };

  const getDataPlaces = async () => {
    try {
      const responseDataPlaces = await getPlaces();
      responseDataPlaces.data.unshift({
        countryName: "All",
        countryCode: "",
      });

      const prepareDatePlaces = responseDataPlaces.data.map((data) => {
        return { name: data.countryName, value: data.countryCode };
      });

      setDataPlaces(prepareDatePlaces);
    } catch (error) {}
  };

  const onApplySearch = async () => {
    setDataEventConcerts([]);
    setDataEventSports([]);
    setDataEventFoodCourt([]);
    getDataEventsApi({});
  };

  const handleLoadMoreSports = () => {
    getDataEventsApi({
      page: dataSearch.paginationSport.currentPage + 1,
      isFetchNext: true,
      category: "SPORT",
    });
  };
  const handleLoadMoreConcerts = () => {
    getDataEventsApi({
      page: dataSearch.paginationConcert.currentPage + 1,
      isFetchNext: true,
      category: "CONCERT",
    });
  };
  const handleLoadMoreFoodCourt = () => {
    getDataEventsApi({
      page: dataSearch.paginationFoodCourt.currentPage + 1,
      isFetchNext: true,
      category: "FOOD_COURT",
    });
  };

  const isShowLoadMoreSport =
    dataSearch.paginationSport.currentPage <
    dataSearch.paginationSport.totalPage;

  const isShowLoadMoreConcert =
    dataSearch.paginationConcert.currentPage <
    dataSearch.paginationConcert.totalPage;

  const isShowLoadMoreFoodCourt =
    dataSearch.paginationFoodCourt.currentPage <
    dataSearch.paginationFoodCourt.totalPage;

  return (
    <>
      <div className="flex flex-col items-center justify-center flex-1 w-full">
        <div className="relative w-full mb-16 md:inline lg:mb-20 md:pt-6">
          <Banner />
          <div className="relative left-0 w-full px-4 mt-8 sm:px-10 lg:px-20">
            <div className="items-end gap-10 p-10 bg-white shadow-filterHome rounded-large lg:flex">
              <div className="w-full mb-8 lg:mb-0">
                <p className="font-normal lg:text-body-1 text-body-2 text-philippine_silver">
                  {t("home.search_event")}
                </p>
                <div className="border-b border-bright_gray">
                  <input
                    onChange={onChangeObjectSearch}
                    name="keyWordSearch"
                    className="w-full pb-2 font-bold bg-white border-none outline-none sm:text-body-1 text-body-2 text-neutral-800 "
                  />
                </div>
              </div>
              <div className="relative w-full mb-8 lg:mb-0">
                <p className="font-normal tex lg:text-body-1 text-body-2 text-philippine_silver">
                  {t("home.time")}
                </p>

                <div className="" />
                <div className="flex items-end h-12">
                  <Datepicker
                    displayFormat={"YYYY-MM-DD"}
                    placeholder="--/--  - --/--"
                    separator={"~"}
                    value={dataSearch.timeValueRangeSearch}
                    onChange={(e) =>
                      onChangeObjectSearch(e, "timeValueRangeSearch")
                    }
                    showShortcuts={true}
                    primaryColor={"indigo"}
                    showFooter={true}
                    readOnly={true}
                    useRange={false}
                    toggleClassName={`${
                      currentLanguage === "ar" ? "left-0" : "right-0"
                    } absolute  h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                    inputClassName="h-12 px-0 text-body-1 xl:text-body-1 lg:text-body-2 text-neutral-800 placeholder-gray-500 focus:ring-0    w-full cursor-default bg-white  text-neutral-800 font-bold border-0 border-b border-[#DDD7E7] rounded-0 hover:border-b-[#1C64F2] hover:cursor-pointer focus:outline-none focus-visible:border-b-[#1C64F2] py-2"
                  />
                </div>
              </div>

              <div className="w-full mb-8 lg:mb-0">
                <p className="font-normal lg:text-body-1 text-body-2 text-philippine_silver">
                  {t("home.place")}
                </p>
                <BaseSelectV2
                  titleStyle="sm:text-body-1 text-body-2 text-neutral-800 font-bold"
                  variant="outline"
                  textSize="lg"
                  options={dataPlaces}
                  onChange={onChangeObjectSearch}
                  value={dataSearch.placeSearch?.name || "All"}
                  name={"placeSearch"}
                />
              </div>
              <div className="h-12 ">
                <div className="w-full h-full font-medium lg:w-35">
                  <BaseButton
                    className="px-8 py-3 lg:px-6"
                    onClick={onApplySearch}
                    sizeButton="md"
                  >
                    <div className="flex flex-row items-center justify-center gap-x-2">
                      <SearchIcon />
                      <p className="font-bold text-body-1">
                        {t("home.search")}
                      </p>
                    </div>
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between px-4 mb-10 lg:flex-row md:flex sm:px-10 lg:px-20">
        <p className="mb-6 font-bold text-purple-900 lg:w-1/2 text-title-1 sm:text-heading-3">
          {t("home.upcoming_event")}
        </p>
        <div className="flex flex-col w-full font-normal sm:gap-x-4 gap-y-4 sm:flex-row lg:w-1/2">
          <div className="h-12 sm:w-1/2">
            {/* =>>>> SUB */}
            <BaseSelectV2
              titleStyle="text-neutral-800 text-body-1"
              className="px-3"
              variant="filled"
              textSize="md"
              value={
                dataFilter?.subCategory?.keyTranslate
                  ? t(dataFilter?.subCategory?.keyTranslate)
                  : dataFilter?.subCategory[nameWithLanguage]
              }
              options={dataFilter.category?.subCategories}
              onChange={onChangeObjectFilter}
              // dataTitle={(e) => (e ? e[nameWithLanguage] : "")}
              dataTitle={(e) => {
                if (e?.keyTranslate) {
                  return t(e?.keyTranslate);
                }
                return e ? e[nameWithLanguage] : "";
              }}
              name={"subCategory"}
            />
          </div>
          {/* =>>>> SUB */}
          <div className="h-12 sm:w-1/2">
            <BaseSelectV2
              titleStyle="text-neutral-800 text-body-1"
              className="px-3"
              variant="filled"
              textSize="md"
              onChange={onChangeObjectFilter}
              name={"category"}
              value={
                dataFilter.category?.keyTranslate
                  ? t(dataFilter.category?.keyTranslate)
                  : dataFilter.category.category
              }
              options={allCategories}
              dataTitle={(e) => {
                if (e?.keyTranslate) {
                  return t(e?.keyTranslate);
                }
                return handleFormatText(e.category);
              }}
            />
          </div>
        </div>
      </div>

      {dataEventSports?.length !== 0 && (
        <>
          <p className="px-4 mb-6 font-bold text-center text-purple-900 sm:text-start sm:px-10 lg:px-20 sm:text-title-1 text-title-2">
            {t("home.sport")}
          </p>
          <div className="grid px-4 gap-y-8 sm:grid-cols-2 sm:gap-4 lg:gap-6 lg:grid-cols-3 sm:px-10 lg:px-20">
            {dataEventSports?.map((event) => {
              return <CardBlog key={event?.id} event={event} />;
            })}
          </div>
        </>
      )}
      {isLoadingSport && (
        <motion.div
          initial={{ opacity: 0.5, scale: 0.9 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{ duration: 0.4 }}
        >
          <div className="grid px-4 sm:grid-cols-2 sm:gap-1 lg:gap-2 lg:grid-cols-3 sm:px-4 lg:px-16">
            {dataCardBlogSkeletonLoading?.map((itemSkeleTon) => (
              <div key={itemSkeleTon} className="w-full ">
                <CardBlogSkeletonLoading />
              </div>
            ))}
          </div>
        </motion.div>
      )}

      <div className="mt-8 mb-10 sm:mt-12">
        {!isLoadingSport &&
          dataEventSports?.length !== 0 &&
          isShowLoadMoreSport && (
            <motion.div
              initial={{ opacity: 0.3, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="flex justify-center ">
                <div className="w-full px-4 font-medium sm:w-auto">
                  <BaseButton
                    className="w-full px-0 font-bold sm:px-12 text-body-2 sm:text-body-1 text-primary-200"
                    onClick={handleLoadMoreSports}
                    variant="outline"
                    sizeButton="md"
                  >
                    <p className="font-bold text-primary-200 sm:text-body-1 text-body-2">
                      {t("load_more")}
                    </p>
                  </BaseButton>
                </div>
              </div>
            </motion.div>
          )}
      </div>

      {dataEventConcerts?.length !== 0 && (
        <div className="">
          <p className="px-4 mb-6 font-bold text-center text-purple-900 sm:text-start sm:px-10 lg:px-20 sm:text-title-1 text-title-2">
            {t("home.concerts")}
          </p>
          <div className="grid px-4 gap-y-8 sm:grid-cols-2 sm:gap-4 lg:gap-6 lg:grid-cols-3 sm:px-10 lg:px-20">
            {dataEventConcerts?.map((event) => {
              return <CardBlog event={event} key={event?.id} />;
            })}
          </div>

          {isLoadingConcerts && (
            <motion.div
              initial={{ opacity: 0.5, scale: 0.9 }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              transition={{ duration: 0.4 }}
            >
              <div className="grid px-4 mb-6 sm:grid-cols-2 sm:gap-1 lg:gap-2 lg:grid-cols-3 sm:px-4 lg:px-16">
                {dataCardBlogSkeletonLoading?.map((itemSkeleTon) => (
                  <div key={itemSkeleTon} className="w-full ">
                    <CardBlogSkeletonLoading />
                  </div>
                ))}
              </div>
            </motion.div>
          )}
          <div className="mt-10 mb-16 sm:mt-12">
            {!isLoadingConcerts &&
              dataEventConcerts?.length !== 0 &&
              isShowLoadMoreConcert && (
                <motion.div
                  initial={{ opacity: 0.3, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className="flex sm:justify-center">
                    <div className="w-full px-4 font-medium sm:w-auto ">
                      <BaseButton
                        className="w-full px-0 font-bold sm:px-12 text-body-2 sm:text-body-1 text-primary-200"
                        onClick={handleLoadMoreConcerts}
                        variant="outline"
                        sizeButton="md"
                      >
                        <p className="font-bold text-primary-200 sm:text-body-1 text-body-2">
                          {t("load_more")}
                        </p>
                      </BaseButton>
                    </div>
                  </div>
                </motion.div>
              )}
          </div>
        </div>
      )}

      {dataEventFoodCourt?.length !== 0 && (
        <>
          <p className="px-4 mb-6 font-bold text-center text-purple-900 sm:text-start sm:px-10 lg:px-20 sm:text-title-1 text-title-2">
            {t("food_court")}
          </p>
          <div className="grid px-4 gap-y-8 sm:grid-cols-2 sm:gap-4 lg:gap-6 lg:grid-cols-3 sm:px-10 lg:px-20">
            {dataEventFoodCourt?.map((event) => {
              return <CardBlog key={event?.id} event={event} />;
            })}
          </div>
        </>
      )}
      <div className="mt-8 mb-10 sm:mt-12">
        {!isLoadingFoodCourt &&
          dataEventFoodCourt?.length !== 0 &&
          isShowLoadMoreFoodCourt && (
            <motion.div
              initial={{ opacity: 0.3, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="flex justify-center ">
                <div className="w-full px-4 font-medium sm:w-auto">
                  <BaseButton
                    className="w-full px-0 font-bold sm:px-12 text-body-2 sm:text-body-1 text-primary-200"
                    onClick={handleLoadMoreFoodCourt}
                    variant="outline"
                    sizeButton="md"
                  >
                    <p className="font-bold text-primary-200 sm:text-body-1 text-body-2">
                      {t("load_more")}
                    </p>
                  </BaseButton>
                </div>
              </div>
            </motion.div>
          )}
        {isLoadingFoodCourt && (
          <motion.div
            initial={{ opacity: 0.5, scale: 0.9 }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            transition={{ duration: 0.4 }}
          >
            <div className="grid px-4 sm:grid-cols-2 sm:gap-1 lg:gap-2 lg:grid-cols-3 sm:px-4 lg:px-16">
              {dataCardBlogSkeletonLoading?.map((itemSkeleTon) => (
                <div key={itemSkeleTon} className="w-full ">
                  <CardBlogSkeletonLoading />
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </div>
      {!isLoadingFoodCourt &&
        !isLoadingConcerts &&
        !isLoadingSport &&
        isEmpty(dataEventFoodCourt) &&
        isEmpty(dataEventConcerts) &&
        isEmpty(dataEventSports) && <NoFound />}
    </>
  );
}
