import CryptoJS from "crypto-js";
import parse from "html-react-parser";

export const handleFormatText = (str) => {
  if (str) {
    return str.replace(/_/g, " ");
  }
  return "";
};

export const handleStringify = (data) => {
  return JSON.stringify(data);
};

export const encryptAES = (data, key) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

export const safelyParse = (data, safetyText = "") => {
  if (data && typeof data === "string") {
    return parse(data);
  }
  return safetyText;
};
