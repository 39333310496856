import i18next from "i18next";
import { useEffect } from "react";

export const useLanguage = () => {
  const currentLanguage = () =>
    i18next.language || window.localStorage.i18nextLng;

  const getNameWithLanguage = () => {
    if (currentLanguage() === "ar") {
      return "nameAr";
    }
    return "nameEn";
  };

  const getDescriptionWithLanguage = () => {
    if (currentLanguage() === "ar") {
      return "descriptionAr";
    }
    return "descriptionEn";
  };

  const getTitleWithLanguage = () => {
    if (currentLanguage() === "ar") {
      return "titleAr";
    }
    return "titleEn";
  };

  const getConditionWithLanguage = () => {
    if (currentLanguage() === "ar") {
      return "conditionAr";
    }
    return "conditionEn";
  };

  const getLocationWithLanguage = () => {
    if (currentLanguage() === "ar") {
      return "locationAr";
    }
    return "locationEn";
  };

  const getCityWithLanguage = () => {
    if (currentLanguage() === "ar") {
      return "cityAr";
    }
    return "cityEn";
  };

  useEffect(() => {
    const inputElements = document.getElementsByTagName("input");

    if (currentLanguage() === "ar") {
      for (let i = 0; i < inputElements.length; i++) {
        // inputElements[i].style.unicodeBidi = "bidi-override";
      }
    } else {
      for (let i = 0; i < inputElements.length; i++) {
        inputElements[i].style.unicodeBidi = "";
      }
    }
  }, [currentLanguage]);

  useEffect(() => {
    const xxxElement = document.getElementById("container");
    const sentOtpElement = document.getElementById("sendOtp");
    const wrapperPhoneInputElement =
      document.getElementById("wrapperPhoneInput");
    const phoneNumberElement = document.getElementById("phoneNumber");

    if (currentLanguage() === "ar") {
      xxxElement ? (xxxElement.style.direction = "LTR") : "";
      sentOtpElement ? (sentOtpElement.style.left = "auto") : "";
      sentOtpElement ? (sentOtpElement.style.right = 0) : "";
      wrapperPhoneInputElement
        ? (wrapperPhoneInputElement.style.paddingLeft = 0)
        : "";
      phoneNumberElement ? (phoneNumberElement.style.direction = "LTR") : "";
      phoneNumberElement ? (phoneNumberElement.style.textAlign = "end") : "";
    }
  }, []);

  return {
    nameWithLanguage: getNameWithLanguage(),
    currentLanguage: currentLanguage(),
    descriptionWithLanguage: getDescriptionWithLanguage(),
    conditionWithLanguage: getConditionWithLanguage(),
    locationWithLanguage: getLocationWithLanguage(),
    cityWithLanguage: getCityWithLanguage(),
    titleWithLanguage: getTitleWithLanguage(),
  };
};
